@use "../variables" as v;

#claim-container{
  position: relative;
  background-image: url("../assets/background-get.png");
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  overflow-y: hidden;


  #claim-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #question-container{
      width: 300px;
      height: 300px;
      margin: 72px 0 82px 0;
      img{
        width: 100%;
        height: 100%;
      }
    }

    #code-input{
      width: 185px;
      height: 39px;
      margin-bottom: 20px;

      input{
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        background: none;
        border: 1px solid rgba(255,255,255,0.4);
        color: white;
        padding: 0;
        font-size: 14px;
        box-sizing: border-box;

        &::placeholder{
          color: white;
        }
      }
    }

    #claim-button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 185px;
      font-size: 14px;
      height: 39px;
      background: linear-gradient(90deg, #DF4432 0%, #EE8174 100%);
      box-shadow: 0 10px 11px rgba(38, 6, 2, 0.47);
      border-radius: 35px;
      color: v.$theme-gold;
    }

  }



}