@use "../variables" as v;

.login {
  position: relative;
  height: 100vh;
  padding: 0 28px;
  background-image: url("../assets/background-login.png");
  background-size: cover;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;


  .title-container{
    padding-top: 78px;
    padding-bottom: 56px;
    width: 130px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    img{
      width: 100%;
      height: 100%;
    }
  }

  .input-container{
    width: 319px;
    height: 50px;
    display: flex;
    margin-bottom: 16px;
    font-size: 14px;

    button{
      font-size: 14px;
    }

    input{
      border:none;
      padding: 0 0 0 22px;
      border-radius: 30px;
      font-size: 14px;
    }

    .phone{
      width: 100%;
      height: 100%;
    }

    .input-wrapper .code{
      width: 180px;
      height:100%;
      margin-right: 18px;
    }

    .code-button{
      width: 100px;
      border-radius: 30px;
      border:none;
      background: white;
      color:rgba(124, 124, 124, 1);
      padding: 0;
    }

    .code-button:disabled{
      background: rgba(124, 124, 124, 1);
      color: white;
    }


    .full-length-button{
      width: 100%;
      height: 100%;
      border-radius: 30px;
      border:none;
      background: rgba(124, 124, 124, 1);
      color: white;
    }

    .checked {
      background: v.$theme-red;
      color: white;
    }
  }

  .agreement-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .agreement-label{
      display: flex;
      margin-left: 10px;
    }

  }

  .theme-color{
    color: v.$theme-red;
    font-size: 14px;
  }
}




