.fullscreen-loading{
  position: absolute;
  left:0;
  top:0;
  z-index: 7;
  height: 100%;
  width: 100vw;
  background-image: url("../assets/background-get.png");
  background-size: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color:white;
  font-size: 16px;

  .loading-container{
      margin-bottom: 17px;
  }

}