#poster-container{
  z-index: 4;
  position: relative;
  width: 100vw;

  img{
    width: 100%;
  }

  .reminder{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 64px;
    bottom: 34px;
    color: white;
    font-size: 14px;

    img{
      margin-top: 10px;
      width:19px;
      height: 33px;
    }
  }

}