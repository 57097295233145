#about-container{
  position: relative;
  background-image: url("../assets/background-about.png");
  background-size: cover;
  height: 100vh;
  z-index: 4;
  color:white;

  #about-content{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    margin-top: 30px;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;

    #about-title{
      font-size: 30px;
      font-weight: 600;
    }
  }
}